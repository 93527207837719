import React from 'react'
import StepsImage from "../../Assets/Images/STAIRCASE-removebg-preview.png"
const WhyCom = () => {
  return (
    <div>
        
        <section id="Newsletter">
<div id ="newsletterp">
    <h3>Why A Community?</h3>
    <br/>
    <p>Community has been the cornerstone of human interaction and growth. At E-CELL SIESGST, we emulate this fundamental aspect by bringing together like-minded individuals sharing a common passion for building and innovating. Join us to network, collaborate, and make a tangible difference in the entrepreneurial landscape.
</p>
   <br/>
 
</div>
</section>
    </div>
  )
}

export default WhyCom
