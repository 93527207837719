import React from 'react'
import Banner from "../../Assets/Images/Group 32.png"
const CommunityBanner = () => {
  return (
    <div>
    <div className='Banner'>
<div className='InnerBanner'>
<h1>COMMUNITY</h1>
<p>"Help will always be given to those who seek it!"</p>
</div>
    </div>
   
    </div>
  )
}

export default CommunityBanner
